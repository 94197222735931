import React from 'react';

import '../styles/main.scss';

import { MetaData } from '../components/MetaData';
import { Imprint } from '../scenes/Imprint';
import { Footer } from '../scenes/Footer';

export default () => (
  <div id="root">
    <Imprint />
    <Footer />

    <MetaData />
  </div>
);
